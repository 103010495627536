<template>
    <div>
        <v-container>
            <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                Presupuestos
            </div>

            <v-form :disabled="loading">
                <v-row>
                    <!-- <v-col class="mt-2" cols="12" sm="6" md="5" lg="4" xl="2">
                        <DirFind class="mb-n7" ref="dirCtrl" v-model="filtros.dir" label="Tienda"></DirFind>
                    </v-col> -->

                    <v-col class="mt-2" cols="6" sm="4" md="4" lg="3" xl="2">
                        <v-select
                            outlined
                            dense
                            v-model="filtros.mes"
                            :items="optionsMes"
                            item-text="label"
                            item-value="value"
                            label="Mes"
                            @change="onChangeFilters"
                        ></v-select>
                    </v-col>
                    
                    <v-col class="mt-2" cols="6" sm="2" md="2" lg="2" xl="2">
                        <v-text-field
                            outlined
                            dense
                            text
                            type="number"
                            v-model="filtros.ano"
                            label="Año"
                            @change="onChangeFilters"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>

            <v-data-table
                :headers="headers"
                :items="dataRows"
                :item-class="actualDayRow"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions: [31]}"
                :server-items-length="totalDataRows"
                :loading="loading"
                style="overflow: scroll"
                mobile-breakpoint="0"
                class="elevation-1 budget-table"
                dense>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.Fecha="{ item }">
                    {{ formatDate(item.Fecha) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.FechaDia="{ item }">
                    {{ formatDateDay(item.Fecha) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.ImpPpt="{ item }">
                    {{ formatMoneda(item.ImpPpt) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.ImpReal="{ item }">
                    <span v-if="actualDayRow(item) == 'actual-day-row' && empId != 8 && empId != 12">0,00€</span>
                    <span v-else>{{ formatMoneda(item.ImpReal) }}</span>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.ImpDesv="{ item }">
                    <span :class="getLabelClass(item.ImpDesv)">
                        {{ formatMoneda(item.ImpDesv) }}
                    </span>
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.ImpMedReal="{ item }">
                    {{ formatMoneda(item.ImpMedReal) }}
                </template>
                <!-- eslint-disable-next-line vue/valid-v-slot -->
                <template v-slot:item.ImpPctDesv="{ item }">
                    <span :class="getLabelClass(item.ImpPctDesv)">
                        {{ formatPct(item.ImpPctDesv) }}
                    </span>
                </template>
                <template v-if="dataRows.length > 0" slot="body.append">
                    <tr class="total-row">
                        <th>Total</th>
                        <th></th>
                        <th class="text-end">
                            {{formatMoneda(dataTotal.ImpPpt)}}
                        </th> 
                        <th class="text-end">
                            {{formatMoneda(dataTotal.ImpReal)}}
                        </th>
                        <th class="text-end">
                            <span :class="getLabelClass(dataTotal.ImpDesv)">
                                {{formatMoneda(dataTotal.ImpDesv)}}
                            </span>
                        </th>
                        <th class="text-end">
                            <span :class="getLabelClass(dataTotal.ImpPctDesv)">
                                {{formatPct(dataTotal.ImpPctDesv)}}
                            </span>
                        </th>
                        <th class="text-end" v-if="empId == 8 || empId == 12">
                            {{dataTotal.NumOpePpt}}
                        </th>
                        <th class="text-end">
                            {{dataTotal.NumOpeReal}}
                        </th>
                        <th class="text-end">
                            {{formatMoneda(dataTotal.ImpMedReal)}}
                        </th>
                    </tr>
                </template>
            </v-data-table>

            <br>
            <p>Evolución Anual</p>
            <v-radio-group v-model="evolucionAnual" row :class="{ 'mb-n1' : loadingChart }">
                <v-radio
                    v-for="opt in evolucionAnualRadioOptions"
                    :key="opt.value"
                    :label="opt.label"
                    :value="opt.value"
                ></v-radio>
            </v-radio-group>

            <v-row v-if="loadingChart" class="my-0 px-3" justify="center">
                <v-progress-linear 
                indeterminate
                rounded
                ></v-progress-linear>
            </v-row>
            <v-container class="chart-container">
                <evolucion-anual-grafico
                ref="evoChart"
                :chart-data="chartData" 
                :options="chartOptions">
                </evolucion-anual-grafico>
                <!-- :class="{'chartHeightFix': !$vuetify.breakpoint.xl }" -->
            </v-container>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
// import DirFind from "./DirFind.vue";
import EvolucionAnualGrafico from "../components/EvolucionAnualGrafico.js";
export default ({
    components: {
        // DirFind, 
        EvolucionAnualGrafico
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            loading: false,
            loadingChart: false,
            datatableLoaded: false,
            filtros: {
                dir: {},
                mes: null,
                ano: null
            },
            evolucionAnual: null,
            optionsMes: [
                { label: 'Enero', value: 1 },
                { label: 'Febrero', value: 2 },
                { label: 'Marzo', value: 3 },
                { label: 'Abril', value: 4 },
                { label: 'Mayo', value: 5 },
                { label: 'Junio', value: 6 },
                { label: 'Julio', value: 7 },
                { label: 'Agosto', value: 8 },
                { label: 'Septiembre', value: 9 },
                { label: 'Octubre', value: 10 },
                { label: 'Noviembre', value: 11 },
                { label: 'Diciembre', value: 12 },
            ],
            totalDataRows: 0,
            dataRows: [],
            dataTotal: {
                ImpPpt: 0,
                ImpReal: 0,
                ImpDesv: 0,
                ImpPctDesv: 0,
                NumOpePpt: 0,
                NumOpeReal: 0,
                NumOpeDesv: 0
            },
            options: {itemsPerPage: 31},
            headers: [
                { text: 'Fecha', width: 100, sortable: false, value: 'Fecha' },
                { text: 'Día semana', width:100, sortable: false, value: 'FechaDia' },
                { text: 'Venta presupuesto', width: 150, sortable: false, value: 'ImpPpt', align: 'end' },
                { text: 'Venta real', width: 100, sortable: false, value: 'ImpReal', align: 'end' },
                { text: 'Importe Desviación', width: 150, sortable: false, value: 'ImpDesv', align: 'end' },
                { text: 'Desviación', width: 100, sortable: false, value: 'ImpPctDesv', align: 'end' },
                { text: 'Clientes presupuesto', width: 150, sortable: false, value: 'NumOpePpt', align: 'end' },
                { text: 'Clientes', width: 100, sortable: false, value: 'NumOpeReal', align: 'end' },
                { text: 'Ticket medio', width: 100, sortable: false, value: 'ImpMedReal', align: 'end' },
            ],
            evolucionAnualRadioOptions: [
                { label: 'Ventas', value: 0 },
                { label: 'Clientes', value: 1 },
                { label: 'Ticket Medio', value: 2 }
            ],
            chartData: {},
            chartOptions: {
                mantainAspectRatio: true,
                aspectRatio: 2,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function(value) {
                                return value.toString().replace('.', ',');
                            }
                        }
                    }]
                },
                elements: {
                    line: {
                        tension: 0,
                        fill: false,
                        borderWidth: 3,
                    }
                },
                tooltips: {
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        label: function (tooltipItem, data) {
                            let formatterOptions = {};
                            if(data.datasets[tooltipItem.datasetIndex].tooltipLabel == 'Clientes') {
                                formatterOptions = {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 2
                                };
                            } else {
                                formatterOptions = {
                                    style: 'currency',
                                    currency: 'EUR',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                };
                            }

                            const formatter = new Intl.NumberFormat('it', formatterOptions);
                            return data.datasets[tooltipItem.datasetIndex].tooltipLabel + ' ' +
                            data.datasets[tooltipItem.datasetIndex].years[tooltipItem.index] + ': '
                            + formatter.format(tooltipItem.yLabel);
                        }
                    },
                    }
            },
            chartValues: {},
            yearValues: [],
            chartValuesOld: {},
            yearValuesOld: []
        }
    },
    watch: {
        //   filtros: {
        //     handler () {
        //       if(this.filtros != null && this.filtros.ano != null) {
        //           this.filtros.ano = Math.trunc(this.filtros.ano);
        //       }
        //       if(!this.datatableLoaded) { return; }
        //       this.getDataFromApi()
        //       .then(data => {
        //           this.dataRows = data.items
        //           this.totalDataRows = data.total
        //           this.getDatatableTotal()
        //           this.getChartData();
        //       });
        //     },
        //     deep: true,
        //   },
      evolucionAnual: {
          handler () {
            this.loadChartValues();
          },
          deep: true
      }
    },
    created() {
        window.addEventListener('resize', this.fixChartAspectRatio);
    },
    destroyed() {
        window.removeEventListener('resize', this.fixChartAspectRatio);
    },
    mounted() {
        this.fixChartAspectRatio(false);
        
        if(this.empId != 8 && this.empId != 12) {
            // Tickets presupuesto no se muestra para Rincon
            this.headers.splice(6, 1);
        }

        if (this.$store.state.esDir) {
            this.filtros.dir = this.$store.state.dir;
        }
        const today = new Date();
        this.filtros.ano = today.getFullYear();
        this.filtros.mes = today.getMonth() + 1;
        
        this.getDataFromApi()
        .then(data => {
            this.dataRows = data.items;
            this.totalDataRows = data.total;
            this.getDatatableTotal();
            this.getChartData();
        });
    },
    methods: {
      actualDayRow(item) {
          if(this.formatDate(item.Fecha) === this.formatDate(new Date())) {
              return 'actual-day-row';
          }
          return '';
      },
      getLabelClass(value) {
          return value < 0 ? 'negative-data' : '';
      },
      getDataFromApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_PPT',
            EmpId: this.empId,
            DirId: this.filtros.dir.DirId,
            Ano: this.filtros.ano,
            Mes: this.filtros.mes
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/dir", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data;
            const total = result.data.length;

            setTimeout(() => {
                this.loading = false;
                this.datatableLoaded = true;
                resolve({
                items,
                total,
              })
            }, 1000)
          }).catch(error => {
            console.log(error);
            this.loading = false;
            this.datatableLoaded = true;
          });
        })
      },
      getChartDataFromApi(ano) {
        this.loadingChart = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_EVOL_PPT',
            EmpId: this.empId,
            DirId: this.filtros.dir.DirId,
            Ano: ano,
            Mes: this.filtros.mes
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/dir", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data;

            setTimeout(() => {
                this.loadingChart = false
                resolve({
                items,
              })
            }, 1000)
          }).catch(error => {
            console.log(error);
            this.loadingChart = false;
          });
        })
      },
      getDatatableTotal() {
        if(this.dataRows.length == 0) {
            this.dataTotal = {};
        } else {
            var rowsFilter = this.dataRows.filter(f => f.NumOpeReal != 0);
            
            this.dataTotal = {
                ImpPpt: rowsFilter.map(i => i.ImpPpt).reduce((result,number)=> result + number),
                ImpReal: rowsFilter.map(i => i.ImpReal).reduce((result,number)=> result + number),
                ImpDesv: 0,
                ImpPctDesv: 0,
                NumOpePpt: rowsFilter.map(i => i.NumOpePpt).reduce((result,number)=> result + number),
                NumOpeReal: rowsFilter.map(i => i.NumOpeReal).reduce((result,number)=> result + number),
                ImpMedPpt: 0
            };

            this.dataTotal.ImpDesv = this.dataTotal.ImpReal - this.dataTotal.ImpPpt;
            this.dataTotal.ImpPctDesv = 100 - (this.dataTotal.ImpPpt * 100 / this.dataTotal.ImpReal);
            this.dataTotal.ImpMedReal = this.dataTotal.ImpReal / this.dataTotal.NumOpeReal;
        }
      },
      getChartData() {
        // Último año
        this.getChartDataFromApi(this.filtros.ano)
        .then(data => {
            if(data.items.length == 0) { data.items = []; }
            this.chartValues = this.mapChartData(data.items);
            // Año anterior
            this.getChartDataFromApi(this.filtros.ano - 1)
            .then(dataOld => {
                if(dataOld.items.length == 0) { dataOld.items = []; }
                this.chartValuesOld = this.mapChartData(dataOld.items);
                this.loadChartValues();
            });
        });
      },
      onChangeFilters() {
        if(this.filtros != null && this.filtros.ano != null) {
            this.filtros.ano = Math.trunc(this.filtros.ano);
        }
        if(!this.datatableLoaded) { return; }
        this.getDataFromApi()
        .then(data => {
            this.dataRows = data.items;
            this.totalDataRows = data.total;
            this.getDatatableTotal();
            this.getChartData();
        });
      },
      loadChartValues() {
        this.fixChartAspectRatio(false);
        this.getYearLabels();
        const datasets = [];
        if(this.evolucionAnual === 0) {
            datasets.push({
                label: 'Ventas último año',
                tooltipLabel: 'Ventas',
                borderColor: '#FE8588',
                pointBackgroundColor: '#D93236',
                data: this.chartValues.Imp,
                years: this.yearValues
            });
            datasets.push({
                label: 'Ventas año anterior',
                tooltipLabel: 'Ventas',
                borderColor: '#F8BB7A',
                pointBackgroundColor: '#DA7000',
                data: this.chartValuesOld.Imp,
                years: this.yearValuesOld
            });
        }
        if(this.evolucionAnual === 1) {
            datasets.push({
                label: 'Clientes último año',
                tooltipLabel: 'Clientes',
                borderColor: '#84B1C5',
                pointBackgroundColor: '#0085FF',
                data: this.chartValues.NumOpe,
                years: this.yearValues
            });
            datasets.push({
                label: 'Clientes año anterior',
                tooltipLabel: 'Clientes',
                borderColor: '#B884C5',
                pointBackgroundColor: '#B90FE4',
                data: this.chartValuesOld.NumOpe,
                years: this.yearValuesOld
            });
        }
        if(this.evolucionAnual === 2) {
            datasets.push({
                label: 'Ticket medio último año',
                tooltipLabel: 'Ticket medio',
                borderColor: '#A7DAA8',
                pointBackgroundColor: '#5ADD7D',
                data: this.chartValues.ImpMed,
                years: this.yearValues
            });
            datasets.push({
                label: 'Ticket medio año anterior',
                tooltipLabel: 'Ticket medio',
                borderColor: '#779973',
                pointBackgroundColor: '#108802',
                data: this.chartValuesOld.ImpMed,
                years: this.yearValuesOld
            });
        }

        this.chartData = {
            labels: this.getChartLabels(),
            datasets: datasets
        }
      },
      getChartLabels() {
          const labelsMeses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
          // const labelsMeses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
          const resultLabels = [];

          const mes = this.filtros.mes != null ? this.filtros.mes : 1;
          // const ano = this.filtros.ano != null ? this.filtros.ano : new Date().getFullYear();
          let mesActual = mes === 12 ? 1 : mes + 1;
          // let anoActual = mes === 12 ? ano : ano - 1;

          for (let i = 1; i <= 12; i++) {
              if(mesActual > 12) {
                  mesActual = 1;
                  // anoActual++;
              }
              const labelActual = labelsMeses[mesActual - 1];
              // const labelActual = labelsMeses[mesActual - 1] + ' ' + anoActual;
              resultLabels.push(labelActual);
              mesActual++;
          }

          return resultLabels;
      },
      getYearLabels() {
          this.yearValues = [];
          this.yearValuesOld = [];

          const mes = this.filtros.mes != null ? this.filtros.mes : 1;
          const ano = this.filtros.ano != null ? this.filtros.ano : new Date().getFullYear();
          let mesActual = mes === 12 ? 1 : mes + 1;
          let anoActual = mes === 12 ? ano : ano - 1;

          for (let i = 1; i <= 12; i++) {
              if(mesActual > 12) {
                  mesActual = 1;
                  anoActual++;
              }
              this.yearValues.push(anoActual);
              this.yearValuesOld.push(anoActual - 1);
              mesActual++;
          }
      },
      mapChartData(data) {
          const result = {
              Imp: [],
              NumOpe: [],
              ImpMed: []
          };
          
          let mesActual = 0;
          let anoActual = 0;

          if(data.length > 0) {
              mesActual = data[0].Mes;
              anoActual = data[0].Ano;
          } else {
              const mes = this.filtros.mes != null ? this.filtros.mes : 1;
              const ano = this.filtros.ano != null ? this.filtros.ano : new Date().getFullYear();
              mesActual = mes === 12 ? 1 : mes + 1;
              anoActual = mes === 12 ? ano : ano - 1;
          }

          for (let i = 1; i <= 12; i++) {
              if(mesActual > 12) {
                  mesActual = 1;
                  anoActual++;
              }
              const dataActual = data.find(d => d.Mes === mesActual && d.Ano === anoActual);
              if(dataActual == null) {
                  result.Imp.push(0);
                  result.NumOpe.push(0);
                  result.ImpMed.push(0);
              } else {
                  result.Imp.push(dataActual.ImpReal.toFixed(2));
                  result.NumOpe.push(dataActual.NumOpeReal);
                  const ticketMedio = dataActual.NumOpeReal === 0 ? 0 :
                    dataActual.ImpReal / dataActual.NumOpeReal;
                  result.ImpMed.push(ticketMedio.toFixed(2));
              }
              mesActual++;
          }

          return result;
      },
      fixChartAspectRatio(renderChart = true) {
          if(window.innerWidth < 600) {
              this.chartOptions.aspectRatio = 1.1;
          }
          else if (window.innerWidth > 1264) {
              this.chartOptions.aspectRatio = 2.15;
          }
          else {
              this.chartOptions.aspectRatio = 2;
          }
          
          if(renderChart && this.$refs.evoChart != null) {
            this.$refs.evoChart.renderChart(this.$refs.evoChart.chartData, this.$refs.evoChart.options);
          }
      },
      formatDate (value) {
        const date = new Date(value);
        return String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear();
      },
      formatDateDay (value) {
        const date = new Date(value);
        const dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
        return dias[date.getDay()];
      },
      formatMoneda (value) {
          // Usamos el formato italiano porque el español no añade
          // el separador de miles cuando es un número de 4 cifras
          const formatter = new Intl.NumberFormat('it', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          return formatter.format(value);
      },
      formatPct (value) {
          const formatter = new Intl.NumberFormat('es', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          return formatter.format(value/100);
      },
      isToday(dateString) {
        const today = new Date();
        const formattedToday = today.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
        return dateString === formattedToday;
      }
    } 
})
</script>
<style scoped>
  .negative-data {
      color: red;
  }
  .chart-container {
    flex-grow: 1;
    min-height: 0;
  }
  .chart-container > div {
    position: relative;
    height: 100%;
  }
</style>
<style>
  .budget-table > .v-data-table__wrapper > table {
      border-collapse: collapse;
  }
  .budget-table .actual-day-row {
      border: 4px solid #ff4848;
  }
  .budget-table tr.total-row {
      background-color: #424242;
      color: white;
  }
  .budget-table tr.total-row:hover {
      background-color: #161616 !important;
  }
  #line-chart {
    min-height: 200px;
    /* max-height: 550px; */
  }
  /* .chartHeightFix #line-chart {
      max-height: 500px;
  } */
</style>
