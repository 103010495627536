var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('div',{staticClass:"text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center"},[_vm._v(" Presupuestos ")]),_c('v-form',{attrs:{"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"6","sm":"4","md":"4","lg":"3","xl":"2"}},[_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.optionsMes,"item-text":"label","item-value":"value","label":"Mes"},on:{"change":_vm.onChangeFilters},model:{value:(_vm.filtros.mes),callback:function ($$v) {_vm.$set(_vm.filtros, "mes", $$v)},expression:"filtros.mes"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"6","sm":"2","md":"2","lg":"2","xl":"2"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","text":"","type":"number","label":"Año"},on:{"change":_vm.onChangeFilters},model:{value:(_vm.filtros.ano),callback:function ($$v) {_vm.$set(_vm.filtros, "ano", $$v)},expression:"filtros.ano"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 budget-table",staticStyle:{"overflow":"scroll"},attrs:{"headers":_vm.headers,"items":_vm.dataRows,"item-class":_vm.actualDayRow,"options":_vm.options,"footer-props":{itemsPerPageOptions: [31]},"server-items-length":_vm.totalDataRows,"loading":_vm.loading,"mobile-breakpoint":"0","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.Fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.Fecha))+" ")]}},{key:"item.FechaDia",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateDay(item.Fecha))+" ")]}},{key:"item.ImpPpt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoneda(item.ImpPpt))+" ")]}},{key:"item.ImpReal",fn:function(ref){
var item = ref.item;
return [(_vm.actualDayRow(item) == 'actual-day-row' && _vm.empId != 8 && _vm.empId != 12)?_c('span',[_vm._v("0,00€")]):_c('span',[_vm._v(_vm._s(_vm.formatMoneda(item.ImpReal)))])]}},{key:"item.ImpDesv",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getLabelClass(item.ImpDesv)},[_vm._v(" "+_vm._s(_vm.formatMoneda(item.ImpDesv))+" ")])]}},{key:"item.ImpMedReal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoneda(item.ImpMedReal))+" ")]}},{key:"item.ImpPctDesv",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getLabelClass(item.ImpPctDesv)},[_vm._v(" "+_vm._s(_vm.formatPct(item.ImpPctDesv))+" ")])]}}])},[(_vm.dataRows.length > 0)?_c('template',{slot:"body.append"},[_c('tr',{staticClass:"total-row"},[_c('th',[_vm._v("Total")]),_c('th'),_c('th',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatMoneda(_vm.dataTotal.ImpPpt))+" ")]),_c('th',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatMoneda(_vm.dataTotal.ImpReal))+" ")]),_c('th',{staticClass:"text-end"},[_c('span',{class:_vm.getLabelClass(_vm.dataTotal.ImpDesv)},[_vm._v(" "+_vm._s(_vm.formatMoneda(_vm.dataTotal.ImpDesv))+" ")])]),_c('th',{staticClass:"text-end"},[_c('span',{class:_vm.getLabelClass(_vm.dataTotal.ImpPctDesv)},[_vm._v(" "+_vm._s(_vm.formatPct(_vm.dataTotal.ImpPctDesv))+" ")])]),(_vm.empId == 8 || _vm.empId == 12)?_c('th',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.dataTotal.NumOpePpt)+" ")]):_vm._e(),_c('th',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.dataTotal.NumOpeReal)+" ")]),_c('th',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatMoneda(_vm.dataTotal.ImpMedReal))+" ")])])]):_vm._e()],2),_c('br'),_c('p',[_vm._v("Evolución Anual")]),_c('v-radio-group',{class:{ 'mb-n1' : _vm.loadingChart },attrs:{"row":""},model:{value:(_vm.evolucionAnual),callback:function ($$v) {_vm.evolucionAnual=$$v},expression:"evolucionAnual"}},_vm._l((_vm.evolucionAnualRadioOptions),function(opt){return _c('v-radio',{key:opt.value,attrs:{"label":opt.label,"value":opt.value}})}),1),(_vm.loadingChart)?_c('v-row',{staticClass:"my-0 px-3",attrs:{"justify":"center"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","rounded":""}})],1):_vm._e(),_c('v-container',{staticClass:"chart-container"},[_c('evolucion-anual-grafico',{ref:"evoChart",attrs:{"chart-data":_vm.chartData,"options":_vm.chartOptions}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }